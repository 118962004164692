<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="layout-top-spacing">
        <div class="layout-spacing">
          <div class="mb-3 d-flex justify-content-between">
            <h3>
              Viewing Payout Request:
              <code>{{ payoutRequest ? payoutRequest.reference : "" }}</code>
            </h3>
            <b-btn variant="primary" :to="{ name: 'Payout Requests' }">
              <i class="fa fa-arrow-left mr-1"></i>
              All Payout Requests
            </b-btn>
          </div>

          <div class="widget-content widget-content-area" v-if="payoutRequest">
            <b-alert :variant="setStatusColor(payoutRequest.status)" show>
              Current Status:
              <b>{{ payoutRequest.status.replace("_", " ") }}</b>
            </b-alert>

            <div class="table-responsive">
              <table class="table table-bordered">
                <!-- Step 1: Information -->
                <tr>
                  <th width="30%">Category</th>
                  <td>
                    <span v-if="payoutRequest.payout_request_sub_category">
                      {{
                        payoutRequest.payout_request_sub_category
                          .payout_request_category.name
                      }}
                      -
                      {{ payoutRequest.payout_request_sub_category.name }}<br />
                      <small class="text-muted">{{
                        payoutRequest.payout_request_sub_category.description
                      }}</small>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th width="30%">Amount</th>
                  <td>{{ formatMoney(payoutRequest.amount) }}</td>
                </tr>
                <tr>
                  <th>Reason</th>
                  <td>{{ payoutRequest.reason }}</td>
                </tr>
                <tr>
                  <th>Requesting User</th>
                  <td>
                    {{ payoutRequest.user.firstname }}
                    {{ payoutRequest.user.lastname }}
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <td>{{ payoutRequest.user.email }}</td>
                </tr>
                <tr>
                  <th>Attachments</th>
                  <td>
                    <a
                      :href="attachment.original_url"
                      target="_blank"
                      class="btn btn-outline-dark mr-2"
                      v-for="attachment in payoutRequest.attachments"
                      :key="attachment.uuid"
                    >
                      {{ attachment.file_name }}
                      <b-badge>{{ attachment.size | prettyBytes }}</b-badge>
                    </a>
                  </td>
                </tr>

                <tr v-if="payoutRequest.bank_account" class="table-warning">
                  <th>Payable Bank Account</th>
                  <td>
                    {{ payoutRequest.bank_account.account_name }}<br />
                    {{ payoutRequest.bank_account.account_number }}<br />
                    {{ payoutRequest.bank_account.bank.name }}
                  </td>
                </tr>

                <!-- Step 2: Recommendation -->
                <tr>
                  <th>Recommendation</th>
                  <td>{{ payoutRequest.recommending_department }}</td>
                </tr>
                <tr>
                  <th></th>
                  <td
                    :class="
                      !payoutRequest.recommender ? 'text-muted font-italic' : ''
                    "
                  >
                    {{
                      payoutRequest.recommender
                        ? `${payoutRequest.recommender.firstname} ${payoutRequest.recommender.lastname}`
                        : "Recommender name will appear here"
                    }}
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <td
                    :class="
                      !payoutRequest.recommender ? 'text-muted font-italic' : ''
                    "
                  >
                    {{
                      payoutRequest.recommender
                        ? payoutRequest.recommender.email
                        : "Recommender email address will appear here"
                    }}
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <td>
                    <div v-if="payoutRequest.recommendation_note">
                      {{ payoutRequest.recommendation_note }}
                    </div>
                    <div v-else>
                      <form
                        v-if="
                          isARecommender && payoutRequest.status == 'pending'
                        "
                        method="POST"
                        @submit.prevent="updatePayout(recommendationForm)"
                        @keydown="recommendationForm.onKeydown($event)"
                      >
                        <AlertError :form="recommendationForm" />

                        <b-form-group
                          :invalid-feedback="
                            recommendationForm.errors.get('accept')
                          "
                          :state="!recommendationForm.errors.has('accept')"
                        >
                          <b-form-checkbox
                            v-model="recommendationForm.accept"
                            switch
                          >
                            Do you recommend this payout?
                            <b
                              :class="
                                'text-' +
                                (recommendationForm.accept
                                  ? 'success'
                                  : 'danger')
                              "
                            >
                              {{ recommendationForm.accept ? "Yes" : "No" }}
                            </b>
                          </b-form-checkbox>
                        </b-form-group>

                        <b-form-group
                          :description="
                            'Why are you ' +
                            (recommendationForm.accept
                              ? 'accepting'
                              : 'declining') +
                            ' this request?'
                          "
                          :invalid-feedback="
                            recommendationForm.errors.get('recommendation_note')
                          "
                          :state="
                            !recommendationForm.errors.has(
                              'recommendation_note'
                            )
                          "
                        >
                          <b-form-textarea
                            id="recommendation_note"
                            v-model="recommendationForm.recommendation_note"
                            placeholder="Recommendation note"
                            rows="2"
                            :state="
                              recommendationForm.errors.has(
                                'recommendation_note'
                              )
                                ? false
                                : null
                            "
                            required
                          ></b-form-textarea>
                        </b-form-group>

                        <b-button type="submit" size="sm" variant="success">
                          <b-spinner
                            label="Loading"
                            v-if="recommendationForm.busy"
                            small
                          ></b-spinner>
                          Update
                        </b-button>
                      </form>
                      <span class="text-muted font-italic" v-else
                        >Recommendation note will appear here</span
                      >
                    </div>
                  </td>
                </tr>

                <!-- Step 3: Management -->
                <tr>
                  <th>Management</th>
                  <td
                    :class="
                      !payoutRequest.manager ? 'text-muted font-italic' : ''
                    "
                  >
                    {{
                      payoutRequest.manager
                        ? `${payoutRequest.manager.firstname} ${payoutRequest.manager.lastname}`
                        : "Manager name will appear here"
                    }}
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <td
                    :class="
                      !payoutRequest.manager ? 'text-muted font-italic' : ''
                    "
                  >
                    {{
                      payoutRequest.manager
                        ? payoutRequest.manager.email
                        : "Manager email address will appear here"
                    }}
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <td>
                    <div v-if="payoutRequest.management_note">
                      {{ payoutRequest.management_note }}
                    </div>
                    <div v-else>
                      <form
                        v-if="
                          isAManager &&
                          (payoutRequest.status == 'pending' ||
                            payoutRequest.status == 'recommendation_approved')
                        "
                        method="POST"
                        @submit.prevent="updatePayout(managementForm)"
                        @keydown="managementForm.onKeydown($event)"
                      >
                        <AlertError :form="managementForm" />

                        <b-form-group
                          description="The requesting user will be paid immediately you accept this request"
                          :invalid-feedback="
                            managementForm.errors.get('accept')
                          "
                          :state="!managementForm.errors.has('accept')"
                        >
                          <b-form-checkbox
                            v-model="managementForm.accept"
                            switch
                          >
                            Do you approve this payout?
                            <b
                              :class="
                                'text-' +
                                (managementForm.accept ? 'success' : 'danger')
                              "
                            >
                              {{ managementForm.accept ? "Yes" : "No" }}
                            </b>
                          </b-form-checkbox>
                        </b-form-group>

                        <b-form-group
                          :description="
                            'Why are you ' +
                            (managementForm.accept
                              ? 'accepting'
                              : 'declining') +
                            ' this request?'
                          "
                          :invalid-feedback="
                            managementForm.errors.get('management_note')
                          "
                          :state="!managementForm.errors.has('management_note')"
                        >
                          <b-form-textarea
                            id="management_note"
                            v-model="managementForm.management_note"
                            placeholder="Management note"
                            rows="2"
                            :state="
                              managementForm.errors.has('management_note')
                                ? false
                                : null
                            "
                            required
                          ></b-form-textarea>
                        </b-form-group>

                        <b-button type="submit" size="sm" variant="success">
                          <b-spinner
                            label="Loading"
                            v-if="managementForm.busy"
                            small
                          ></b-spinner>
                          Update
                        </b-button>
                      </form>
                      <span class="text-muted font-italic" v-else
                        >Management note will appear here</span
                      >
                    </div>
                  </td>
                </tr>

                <!-- Step 4: Payment -->
                <tr>
                  <th>Payment Status</th>
                  <td>{{ payoutRequest.payment_note }}</td>
                </tr>

                <tr>
                  <th>Payment Reference</th>
                  <td>{{ payoutRequest.payment_reference }}</td>
                </tr>

                <tr>
                  <th>Created Date</th>
                  <td>{{ formatFancyDateFull(payoutRequest.created_at) }}</td>
                </tr>
                <tr>
                  <th>Last Modified Date</th>
                  <td>{{ formatFancyDateFull(payoutRequest.updated_at) }}</td>
                </tr>
              </table>
            </div>
          </div>

          <div v-else>
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-card>
                  <b-skeleton width="85%"></b-skeleton>
                  <b-skeleton width="55%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                </b-card>
              </template>
            </b-skeleton-wrapper>

            <b-alert variant="warning" :show="!loading"
              >No record was found</b-alert
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/utils/http";
import { endpoints } from "@/utils/endpoints";
import { mapGetters } from "vuex";

export default {
  name: "ViewPayoutRequest",
  props: ["reference"],
  data() {
    return {
      payoutRequest: null,
      recommendationForm: new this.$Form({
        accept: false,
        recommendation_note: "",
        callback_url: "",
      }),
      managementForm: new this.$Form({
        accept: false,
        management_note: "",
        callback_url: "",
      }),
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "user",
    }),
    isARecommender() {
      if (this.authUser.role == this.payoutRequest.recommending_department) {
        if (this.payoutRequest.recommending_user_id) {
          return this.payoutRequest.recommending_user_id == this.authUser.id;
        }

        return true;
      }

      return false;
    },
    isAManager() {
      return this.authUser.role == "management";
    },
  },
  methods: {
    fetchRecord() {
      this.loading = true;

      http
        .get(
          `${endpoints.VIEW_PAYOUT_REQUEST.replace(
            ":reference",
            this.reference
          )}?include=user,recommender,manager,bankAccount,payoutRequestSubCategory`
        )
        .then((response) => {
          this.payoutRequest = response;
        })
        .catch((error) => {
          this.$toast.error(error.response?.message.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setStatusColor(data) {
      let color = "info";

      switch (data) {
        case "recommendation_approved":
        case "management_approved":
        case "payment_successful":
          color = "success";
          break;
        case "recommendation_declined":
        case "management_declined":
        case "payment_failed":
          color = "danger";
          break;
      }

      return color;
    },
    updatePayout(form) {
      form.callback_url = window.location.href.replace(
        `/${this.payoutRequest.reference}`,
        ""
      );

      form
        .patch(
          endpoints.UPDATE_PAYOUT_REQUEST.replace(":id", this.payoutRequest.id)
        )
        .then((response) => {
          this.$toast.success(response);
          this.fetchRecord();
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.message.message
              ? error.response?.message.message
              : error.response?.message
          );

          if (error.response.status == 406) {
            this.fetchRecord();
          }
        });
    },
  },
  mounted() {
    this.fetchRecord();
  },
};
</script>
